import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { DashboardLoadService } from './dashboard-load.service';

export function initApp(appLoadService: DashboardLoadService): () => Promise<boolean> {
  return (): Promise<boolean> => appLoadService.initializeApp();
}

export function validateSessionToken(appLoadService: DashboardLoadService): () => Promise<boolean> {
  return (): Promise<boolean> => appLoadService.validateAuthenticationToken();
}

@NgModule({
  imports: [HttpClientModule],
  providers: [
    DashboardLoadService,
    {
      provide: APP_INITIALIZER,
      useFactory: initApp,
      deps: [DashboardLoadService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: validateSessionToken,
      deps: [DashboardLoadService],
      multi: true,
    },
  ],
})
export class DashboardLoadModule {}
