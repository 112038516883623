import { FeatureFlags } from '@accredible-frontend-v2/models';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { DashboardDepartment, DashboardIssuer } from '../../shared/models/department-details';

export const DashboardStoreActions = createActionGroup({
  source: 'Dashboard',
  events: {
    loadFeatureFlags: emptyProps(),
    loadFeatureFlagsSuccess: props<{ featureFlags: FeatureFlags }>(),
    loadFeatureFlagsFailure: props<{ error: any }>(),

    loadIssuer: emptyProps(),
    loadIssuerSuccess: props<{ issuer: DashboardIssuer }>(),
    loadIssuerFailure: props<{ error: any }>(),

    loadDepartments: emptyProps(),
    loadDepartmentsSuccess: props<{ departments: DashboardDepartment[] }>(),
    loadDepartmentsFailure: props<{ error: any }>(),

    updateDetails: props<{ fieldName: string; value: string | number }>(),
    updateDetailsSuccess: props<{ issuer: DashboardIssuer }>(),
    updateDetailsFailure: props<{ error: any }>(),

    resetValue: props<{ name: string }>(),
    resetState: emptyProps(),
  },
});
