import { EmbeddingService } from '@accredible-frontend-v2/embedding';
import { environment } from '@accredible-frontend-v2/envs';
import { AccredibleToastModule } from '@accredible-frontend-v2/new-components/toast';
import { AccredibleBrowserHistoryServiceModule } from '@accredible-frontend-v2/services/browser-history';
import { AccredibleBrowserStorageServiceModule } from '@accredible-frontend-v2/services/browser-storage';
import { AccredibleCookiesServiceModule } from '@accredible-frontend-v2/services/cookies';
import {
  AccredibleLanguageServiceModule,
  CUSTOM_DATE_FORMATS,
  CustomDatePickerAdapter,
} from '@accredible-frontend-v2/services/language';
import { AccredibleToastModule as AccredibleToastModuleOld } from '@accredible-frontend-v2/toast';
import { accredibleCustomThemesMetadata } from '@accredible-frontend-v2/utils/themes';
import { NgModule } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { WebSocketProgressChannelService } from '../shared/services/ws-progress-channel/ws-progress-channel.service';
import { ThemeHelper } from '../themes/theme.helper';
import { DashboardLoadModule } from './dashboard-load.module';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardContainer } from './dashboard.container';
import { DashboardEffects } from './stores/dashboard.effects';
import { metaReducers, reducers } from './stores/reducer';

const CORE_MODULES = [DashboardLoadModule, DashboardRoutingModule];

const CORE_ACCREDIBLE_LIBS_MODULES = [
  AccredibleBrowserHistoryServiceModule,
  AccredibleBrowserStorageServiceModule,
  AccredibleCookiesServiceModule,
  AccredibleToastModuleOld,
  AccredibleToastModule,
  AccredibleLanguageServiceModule.forRoot(
    accredibleCustomThemesMetadata[ThemeHelper.getTheme()].languages,
  ),
];

const CORE_NPM_MODULES = [RecaptchaV3Module];

const CORE_SERVICES = [EmbeddingService, WebSocketProgressChannelService];

const CORE_STORE_EFFECTS = [DashboardEffects];

const MATERIAL_MODULES = [MatSidenavModule];

const CUSTOM_MATERIAL_DATE_ADAPTER = [
  {
    provide: DateAdapter,
    useClass: CustomDatePickerAdapter,
  },
  { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
];

@NgModule({
  declarations: [DashboardContainer],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,

    ...CORE_MODULES,
    ...CORE_ACCREDIBLE_LIBS_MODULES,
    ...CORE_NPM_MODULES,
    ...MATERIAL_MODULES,

    // NGRX
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    environment.type !== 'production'
      ? StoreDevtoolsModule.instrument({ connectInZone: true })
      : [],
    EffectsModule.forRoot(CORE_STORE_EFFECTS),
  ],
  providers: [
    ...CORE_SERVICES,
    ...CUSTOM_MATERIAL_DATE_ADAPTER,
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.recaptchaV3SiteKey,
    },
  ],
  bootstrap: [DashboardContainer],
})
export class DashboardModule {}
