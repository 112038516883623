import { NgModule } from '@angular/core';
import { EmbeddingRouterLinkDirective } from './embedding-router-link.directive';

@NgModule({
  imports: [],
  exports: [EmbeddingRouterLinkDirective],
  declarations: [EmbeddingRouterLinkDirective],
  providers: [],
})
export class EmbeddingRouterLinkDirectiveModule {}
