import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, switchMap } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { DashboardState } from '../../../core/stores/dashboard.reducer';
import {
  selectDashboardIsIssuerLoaded,
  selectDepartmentPermissions,
} from '../../../core/stores/dashboard.selectors';

@Injectable({
  providedIn: 'root',
})
export class EmailCampaignsGuard implements CanActivate {
  constructor(
    private readonly _router: Router,
    private readonly _dashboardStore: Store<DashboardState>,
  ) {}

  canActivate(): Observable<UrlTree | boolean> {
    return this._dashboardStore.select(selectDashboardIsIssuerLoaded).pipe(
      first((isLoaded) => isLoaded),
      switchMap(() => {
        return this._dashboardStore.select(selectDepartmentPermissions).pipe(
          first((permissions) => !!permissions),
          map((permissions) => {
            return permissions.campaigns_plan !== null || this._router.createUrlTree(['/']);
          }),
        );
      }),
    );
  }
}
