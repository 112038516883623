import { EmbeddingService } from '@accredible-frontend-v2/embedding';
import { environment } from '@accredible-frontend-v2/envs';
import { DashboardCorporation } from '@accredible-frontend-v2/models';
import { AccredibleCookiesService } from '@accredible-frontend-v2/services/cookies';
import {
  AccredibleLanguageService,
  getAvailableLanguages,
  registerSupportedLocalesData,
} from '@accredible-frontend-v2/services/language';
import { addGainsightPXCode } from '@accredible-frontend-v2/utils/gainsight-px';
import { addHotjar } from '@accredible-frontend-v2/utils/hotjar-helper';
import { accredibleCustomThemesMetadata } from '@accredible-frontend-v2/utils/themes';
import { WindowHelper } from '@accredible-frontend-v2/utils/window-helper';
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, first } from 'rxjs/operators';
import {
  DashboardEmbeddingDataPayload,
  DashboardEmbeddingService,
} from '../shared/services/db-embedding-service/db-embedding.service';
import { ThemeHelper } from '../themes/theme.helper';

interface InitialDashboardV1Data {
  isAdmin: boolean;
  departmentId: number;
  issuerLoginId: number;
  apiPermission: boolean;
  corporation: DashboardCorporation;
}

export enum URLParams {
  ORIGIN = 'origin', // This is required to enable message posting from the iframe to the host which have different domains
  SESSION_TOKEN = 'session_token',
  ISSUER_TOKEN = 'issuer_token',
  ISSUER_LOGIN_ID = 'issuerLoginId',
  DEPARTMENT_ID = 'departmentId',
  IS_ADMIN = 'isAdmin',
  API_KEY = 'apiKey',
  CONSUMER_KEY = 'consumerKey',
  CONSUMER_SECRET = 'consumerSecret',
  ORGANIZATION_ID = 'organizationId',
}

const DASHBOARD_THEME_NAME = 'dashboard.accredible.com';
const HOTJAR_ID = 2524761;

@UntilDestroy()
@Injectable()
export class DashboardLoadService {
  constructor(
    @Inject(DOCUMENT) private readonly _document: Document,
    private readonly _cookies: AccredibleCookiesService,
    private readonly _language: AccredibleLanguageService,
    private readonly _embedding: EmbeddingService,
    private readonly _matIconRegistry: MatIconRegistry,
    private readonly _domSanitizer: DomSanitizer,
    private readonly _dbEmbedding: DashboardEmbeddingService,
  ) {}

  initializeApp(): Promise<boolean> {
    return new Promise((resolve) => {
      // We can do pre-app initialization here
      this._setLanguages();
      this._registerAccredibleSvgIcons();

      if (WindowHelper.isInIframe()) {
        this._dbEmbedding.setupEmbeddingEventListeners();
      }

      ThemeHelper.setThemeFavicon(ThemeHelper.getTheme());
      ThemeHelper.loadThemeStyles(ThemeHelper.getTheme())
        .then(() => {
          resolve(true);
        })
        .catch(() => {
          console.error('Theme styles failed to load');
          resolve(false);
        });

      addHotjar(HOTJAR_ID);
      addGainsightPXCode(environment.dashboardGainsightTagKey);
    });
  }

  validateAuthenticationToken(): Promise<boolean> {
    return new Promise((resolve) => {
      const urlParams = new URLSearchParams(this._document.location.search);

      // Check if token is in URL query params
      // TODO V1(Fred): Remove embedding service logic when we stop using iframes for dashboard
      if (urlParams.has(URLParams.ORIGIN)) {
        this._embedding.setOrigin(urlParams.get(URLParams.ORIGIN));
      }

      // Setup subscription to authListener
      this._dbEmbedding.authListener$
        .pipe(untilDestroyed(this))
        .subscribe((data: { jwt: string }) => {
          this._cookies.set(URLParams.SESSION_TOKEN, data.jwt);

          if (this._cookies.check(URLParams.ISSUER_TOKEN)) {
            // If session token exists, user is logged in
            resolve(true);
          } else {
            // User is not logged in
            resolve(false);
          }
        });

      // Request auth from v1 - we listen for the response from the 'auth' post message in the authListener$ above
      this._setAuth();
    });
  }

  private _setLanguages(): void {
    // Register supported languages
    const supportedLanguages = getAvailableLanguages(
      accredibleCustomThemesMetadata[ThemeHelper.getTheme()].languages,
    );
    registerSupportedLocalesData(supportedLanguages);

    // Set active language
    this._language.setLanguage('en', DASHBOARD_THEME_NAME);
  }

  private _registerAccredibleSvgIcons(): void {
    this._matIconRegistry.addSvgIcon(
      'user',
      this._domSanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/user.svg'),
    );
  }

  private _setAuth(): void {
    // TODO V1(Fred): Remove this logic when we move away from V1
    this._embedding.postMessage({ type: 'authenticate' });

    this._embedding.postMessage({
      type: 'processData',
      payload: { action: 'getIssuerInfo' },
    });

    this._dbEmbedding.dataListener$
      .pipe(
        filter(
          (payload: DashboardEmbeddingDataPayload<InitialDashboardV1Data>) =>
            payload.action === 'getIssuerInfo',
        ),
        // FIXME(Matt): We could consider removing first() when we are able to switch departments in v2
        first(),
      )
      .subscribe((payload: DashboardEmbeddingDataPayload<InitialDashboardV1Data>) => {
        this._embedding.setV1Data(
          payload.data.issuerLoginId,
          payload.data.isAdmin,
          payload.data.apiPermission,
          payload.data.departmentId,
          payload.data.corporation,
        );
      });
  }
}
