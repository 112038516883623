import { environment } from '@accredible-frontend-v2/envs';
import { filter, Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { WebSocketMessageEvent } from './models/websocket.model';

export class AccredibleWebSocketService {
  private _WEBSOCKET_CHANNEL: string;
  private _WEBSOCKET_PROTOCOL = location.hostname === 'localhost' ? 'ws' : 'wss';
  private readonly _WEBSOCKET_URL = `${environment.socketUrl.replace(
    /^https?/,
    this._WEBSOCKET_PROTOCOL,
  )}/cable`;
  private _connection$: WebSocketSubject<WebSocketMessageEvent>;

  WEBSOCKET_IDENTIFIER: string;

  constructor(private readonly _departmentId: number, private readonly _sessionToken: string) {
    this._WEBSOCKET_CHANNEL = 'BulkProgressChannel'; // default channel
    this.WEBSOCKET_IDENTIFIER = `{"channel":"${this._WEBSOCKET_CHANNEL}"}`;
  }

  connect(channel: string = null): Observable<WebSocketMessageEvent> {
    if (channel) {
      this._WEBSOCKET_CHANNEL = channel;
      this.WEBSOCKET_IDENTIFIER = `{"channel":"${this._WEBSOCKET_CHANNEL}"}`;
    }

    const webSocketUrl = `${this._WEBSOCKET_URL}?token=${this._sessionToken}&organization_id=${this._departmentId}`;
    const connect = this.connect.bind(this);

    if (!this._connection$) {
      this._connection$ = webSocket({
        url: webSocketUrl,
        closeObserver: {
          next(closeEvent) {
            // Reconnects if the connection was not closed via a close handshake
            if (!closeEvent.wasClean) {
              connect();
            }
          },
        },
      });

      this._connection$.next({
        command: 'subscribe',
        identifier: this.WEBSOCKET_IDENTIFIER,
      });
    }

    return <Subject<WebSocketMessageEvent>>this._connection$.pipe(
      tap({
        error: (err) => {
          if (!err.wasClean) {
            this.connect();
          }
        },
        complete: () => console.log('Connection Closed'),
      }),
      filter((message) => message.type !== 'ping'),
    );
  }

  closeConnection(): void {
    if (this._connection$) {
      this._connection$.complete();
      this._connection$ = null;
    }
  }
}
