<div class="version">{{ version }}</div>

<mat-sidenav-container>
  <main class="dashboard-container"
    [class.embed-with-sidebar]="isEmbeddedRoute">
    <router-outlet></router-outlet>
  </main>
</mat-sidenav-container>


