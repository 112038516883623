import { environment } from '@accredible-frontend-v2/envs';

export const addHotjar = (id: number): void => {
  if (environment.type === 'production' && location.hostname !== 'localhost') {
    const scriptTag = document.createElement('script');
    scriptTag.type = 'text/javascript';
    scriptTag.async = true;
    scriptTag.innerHTML = `(
      function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:${id},hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');
        r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
      }
    )(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`;

    document.head.insertBefore(scriptTag, document.head.firstChild);
  }
};

export const setHotjarAttributes = (id: number, data: Record<string, unknown>): void => {
  const hotjar = (<any>window)['hj'];
  if (hotjar) {
    hotjar('identify', id || null, data);
  }
};
