export interface WebSocketMessageEvent {
  type?: string;
  message?: unknown;

  identifier?: string;
  command?: string;
}

export enum WebSocketStatusType {
  PROCESSING = 'processing',
  COMPLETED = 'completed',
  WAITING = 'waiting',
}
