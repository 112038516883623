import { EmbeddingService } from '@accredible-frontend-v2/embedding';
import { AccountsRedirectionKey } from '@accredible-frontend-v2/services/accounts-redirection';
import { AccredibleCookiesService } from '@accredible-frontend-v2/services/cookies';
import { AccredibleWebSocketService } from '@accredible-frontend-v2/services/websocket';
import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { WebSocketProgressMessage } from './ws-progress-channel.model';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class WebSocketProgressChannelService {
  private readonly _message$ = new BehaviorSubject<WebSocketProgressMessage<unknown>>(null);
  message$ = this._message$.asObservable();

  private _webSocket: AccredibleWebSocketService;

  constructor(
    private readonly _cookies: AccredibleCookiesService,
    private readonly _embedding: EmbeddingService,
  ) {
    const sessionToken = this._cookies.get(AccountsRedirectionKey.SESSION_TOKEN_COOKIE);
    const departmentId = this._embedding.departmentId;
    this._webSocket = new AccredibleWebSocketService(departmentId, sessionToken);
  }

  connect(): void {
    this._webSocket
      .connect('Issuer::ProgressChannel')
      .pipe(
        untilDestroyed(this),
        map((messageEvent) => messageEvent.message),
        catchError((error) => throwError(() => new Error(error))),
      )
      .subscribe((message: WebSocketProgressMessage<unknown>) => {
        this._message$.next(message);
      });
  }

  resetMessage(): void {
    this._message$.next(null);
  }

  closeConnection(): void {
    this._message$.complete();
    this._webSocket.closeConnection();
  }
}
