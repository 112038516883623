import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subject } from 'rxjs';
import { URLParams } from '../../../core/dashboard-load.service';

export interface DashboardEmbeddingDataPayload<T> {
  action: string;
  data: T;
}

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class DashboardEmbeddingService {
  // Setup listener subjects
  authListener$ = new Subject();
  routeListener$ = new Subject();
  dataListener$ = new Subject<DashboardEmbeddingDataPayload<any>>();

  constructor(
    @Inject(DOCUMENT) private readonly _document: Document,
    private readonly _router: Router,
  ) {}

  setupEmbeddingEventListeners(): void {
    const urlParams = new URLSearchParams(this._document.location.search);
    const origin = urlParams.get(URLParams.ORIGIN);

    // if the route of v1 changes then change v2
    this.routeListener$
      .pipe(untilDestroyed(this))
      .subscribe((data: { path: string; queryParams }) => {
        this._router
          .navigate([data.path], { queryParams: data.queryParams, skipLocationChange: true })
          .then();
      });

    // Listen for messages from v1
    const messageEventListener = (event: MessageEvent): void => {
      if (event.origin === origin) {
        switch (event.data.type) {
          case 'auth':
            this.authListener$.next({ jwt: event.data.payload });
            break;
          case 'routing':
            this.routeListener$.next(event.data.payload);
            break;
          case 'data':
            this.dataListener$.next(event.data.payload);
            break;
        }
      }
    };
    window.addEventListener('message', messageEventListener, false);
  }
}
