import { environment } from '@accredible-frontend-v2/envs';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { dashboardFeatureKey, DashboardState } from './dashboard.reducer';

export const selectDashboardState = createFeatureSelector<DashboardState>(dashboardFeatureKey);

export const selectDashboardStateAction = createSelector(
  selectDashboardState,
  (state) => state.action,
);

export const selectDashboardFeatureFlags = createSelector(
  selectDashboardState,
  (state) => state.featureFlags,
);

export const selectIssuer = createSelector(selectDashboardState, (state) => state.issuer);

export const selectDashboardIsIssuerLoaded = createSelector(
  selectDashboardState,
  (state) => state.issuerLoaded,
);

export const selectWhiteLabelDomain = createSelector(selectDashboardState, (state) => {
  let whiteLabelDomain = state.issuer.department.whitelabel_domain || environment.credentialNetUrl;

  if (state.issuer.department.whitelabel_domain) {
    // If we do have a whitelabel domain, we need to prepend it with https, always
    whiteLabelDomain = 'https://' + whiteLabelDomain;
  }

  if (whiteLabelDomain.endsWith('/')) {
    return whiteLabelDomain;
  } else {
    return whiteLabelDomain + '/';
  }
});

// To be a proper timestamp, we need to add the three 0s at the end.
// Otherwise, the value that comes from V1 always defaults to the year 1970
export const selectDepartmentCreationDate = createSelector(
  selectDashboardState,
  (state) => new Date(parseInt(state.issuer.department.created_at + '000')),
);

export const selectDepartmentPermissions = createSelector(selectDashboardState, (state) => {
  return state.issuer.department.pricing.features;
});

export const selectIssuerPermissions = createSelector(
  selectDashboardState,
  (state) => state.issuer.permissions,
);

export const selectDepartmentName = createSelector(
  selectDashboardState,
  (state) => state.issuer.department.name,
);

export const selectDashboardDepartments = createSelector(
  selectDashboardState,
  (state) => state.departments,
);

export const selectIssuerCreditsLeft = createSelector(
  selectDashboardState,
  (state) => state.issuer.department.certificate_left,
);

export const selectDashboardMFAStatus = createSelector(
  selectDashboardState,
  (state) => state.issuer.department.security_setting.mfa_enabled,
);
