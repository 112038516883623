import { Directive, HostBinding, HostListener, Input } from '@angular/core';
import { Router } from '@angular/router';
import { EmbedMessageNavigate } from '../embedding.model';
import { EmbeddingService } from '../service/embedding.service';

/**
 * embeddingHrefUrl - This is not what makes the actual navigation, because of the event.preventDefault(), event.stopPropagation() on HostListener('click').
 *                    This is used ONLY to create the href in order for the user to be able to right-click and open in new tab/window.
 *
 * embeddingRoute    - This is used to navigate to a V1 route or a V2 route that is outside the current V1 parent route
 *                     e.g. navigating from 'issuer.dashboard.credentials' to 'issuer.dashboard.groups'
 *
 * If navigating from a V2 route to another V2 route that is inside the same V1 parent route you should use [embeddingRouterLink] instead of [embeddingRoute]
 * e.g. navigating from 'issuer.dashboard.credentials' to 'issuer.dashboard.credentials.view' or
 *      navigating from 'issuer.dashboard.credentials.view' to 'issuer.dashboard.credentials'
 */

@Directive({
  selector: '[embeddingRoute], [embeddingHrefUrl]',
})
export class EmbeddingRouterLinkDirective {
  @Input()
  embeddingRouterLink: string; // Absolute path
  @Input()
  embeddingRoute: string;
  @Input()
  embeddingRouteParams: unknown;
  @Input()
  embeddingId: number | string;
  @Input()
  embeddingHrefUrl: string;

  constructor(private readonly _router: Router, private readonly _embedding: EmbeddingService) {}

  @HostBinding('href')
  get createHref(): string {
    return `${this._embedding.origin || ''}${this.embeddingHrefUrl}`;
  }

  @HostListener('click', ['$event'])
  onClick(event: Event): void {
    event.preventDefault();
    event.stopPropagation();

    if (this.embeddingRouterLink) {
      this._router.navigateByUrl(this.embeddingRouterLink);
    } else if (this.embeddingRoute) {
      this._embedding.postMessage<EmbedMessageNavigate>({
        type: 'navigate',
        payload: {
          id: this.embeddingId,
          route: this.embeddingRoute,
          routeParams: this.embeddingRouteParams,
          isNavigatingFromV2ToV1: true,
        },
      });
    }
  }
}
