import { FeatureFlags } from '@accredible-frontend-v2/models';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { DashboardDepartment, DashboardIssuer } from '../../shared/models/department-details';
import { DashboardApiService } from '../../shared/services/db-api/db-api.service';

/**
 * This is called DashboardStoreApiService, breaking convention,
 * to help distinguish from the DashboardApiService
 */
@Injectable({
  providedIn: 'root',
})
export class DashboardStoreApiService extends DashboardApiService {
  loadFeatureFlags(): Observable<FeatureFlags> {
    const url = `/v1/issuer/organizations/${this.embedding.departmentId}/features`;

    return this._get(url).pipe(
      map((res) => this._handleResponse(res, 'features')),
      catchError((res) => this._handleError(res)),
    );
  }

  loadIssuer(): Observable<DashboardIssuer> {
    const url = `/v1/organizations/${this.embedding.departmentId}/details`;

    return this._get(url).pipe(
      map((res) => this._handleResponse(res, 'issuer')),
      catchError((res) => this._handleError(res)),
    );
  }

  getDepartments(): Observable<DashboardDepartment[]> {
    const url = '/v1/departments/all';

    return this._post(url, {}).pipe(
      map((res) => this._handleResponse(res, 'departments')),
      catchError((res) => this._handleError(res)),
    );
  }

  updateDetails(fieldName: string, value: string | number): Observable<DashboardIssuer> {
    const url = `/v1/organizations/${this.embedding.departmentId}/update`;

    return this._post(url, { issuer: { [fieldName]: value } }).pipe(
      map((res) => this._handleResponse(res, 'issuer')),
      catchError((res) => this._handleError(res)),
    );
  }
}
