import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import { DashboardStoreActions } from './dashboard.actions';
import { DashboardStoreApiService } from './dashboard.service';

@Injectable()
export class DashboardEffects {
  loadFeatureFlags = createEffect(() =>
    this._actions$.pipe(
      ofType(DashboardStoreActions.loadFeatureFlags),
      exhaustMap(() =>
        this._dashboardService.loadFeatureFlags().pipe(
          map((featureFlags) => DashboardStoreActions.loadFeatureFlagsSuccess({ featureFlags })),
          catchError((error) => of(DashboardStoreActions.loadFeatureFlagsFailure({ error }))),
        ),
      ),
    ),
  );

  loadIssuer = createEffect(() =>
    this._actions$.pipe(
      ofType(DashboardStoreActions.loadIssuer),
      exhaustMap(() =>
        this._dashboardService.loadIssuer().pipe(
          map((issuer) => DashboardStoreActions.loadIssuerSuccess({ issuer })),
          catchError((error) => of(DashboardStoreActions.loadIssuerFailure({ error }))),
        ),
      ),
    ),
  );

  getDepartments = createEffect(() =>
    this._actions$.pipe(
      ofType(DashboardStoreActions.loadDepartments),
      exhaustMap(() =>
        this._dashboardService.getDepartments().pipe(
          map((departments) => DashboardStoreActions.loadDepartmentsSuccess({ departments })),
          catchError((error) => of(DashboardStoreActions.loadDepartmentsFailure({ error }))),
        ),
      ),
    ),
  );

  updateDetails = createEffect(() =>
    this._actions$.pipe(
      ofType(DashboardStoreActions.updateDetails),
      exhaustMap((action) =>
        this._dashboardService.updateDetails(action.fieldName, action.value).pipe(
          map((issuer) => DashboardStoreActions.updateDetailsSuccess({ issuer })),
          catchError((error) => of(DashboardStoreActions.updateDetailsFailure({ error }))),
        ),
      ),
    ),
  );

  constructor(private _actions$: Actions, private _dashboardService: DashboardStoreApiService) {}
}
