import { environment } from '@accredible-frontend-v2/envs';
import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import * as DashboardStore from './dashboard.reducer';
import { dashboardFeatureKey } from './dashboard.reducer';

export interface State {
  [dashboardFeatureKey]: DashboardStore.DashboardState;
}

export const reducers: ActionReducerMap<State> = {
  [dashboardFeatureKey]: DashboardStore.reducer,
};

export const metaReducers: MetaReducer<State>[] = environment.type !== 'production' ? [] : [];
