import { FeatureFlags } from '@accredible-frontend-v2/models';
import { Action, createReducer, on } from '@ngrx/store';
import { DashboardDepartment, DashboardIssuer } from '../../shared/models/department-details';
import { DashboardStoreActions } from './dashboard.actions';

export const dashboardFeatureKey = 'dashboard';

export interface DashboardState {
  featureFlags: FeatureFlags;
  issuer: DashboardIssuer;
  issuerLoaded: boolean;
  departments: DashboardDepartment[];

  action: DashboardStateAction;
  payload: any;
  error: any;
}

export enum DashboardStateAction {
  NO_ACTION,
  DEPARTMENTS_LOADED,
  HAS_ERROR,
}

const initialStateHandling: Partial<DashboardState> = {
  action: DashboardStateAction.NO_ACTION,
  payload: null,
  error: null,
};

export const initialState = <DashboardState>{
  issuer: {
    department: {
      pricing: {
        features: {},
      },
      security_setting: {},
    },
  },
  issuerLoaded: false,
  departments: [],
  featureFlags: null,
  ...initialStateHandling,
};

const dashboardReducer = createReducer(
  initialState,

  on(DashboardStoreActions.loadFeatureFlags, (state) => ({
    ...state,
    ...initialStateHandling,
  })),
  on(DashboardStoreActions.loadFeatureFlagsSuccess, (state, { featureFlags }) => ({
    ...state,
    featureFlags,
  })),
  on(DashboardStoreActions.loadFeatureFlagsFailure, (state) => ({
    ...state,
    action: DashboardStateAction.HAS_ERROR,
  })),

  on(DashboardStoreActions.loadIssuer, (state) => ({
    ...state,
    ...initialStateHandling,
  })),
  on(DashboardStoreActions.loadIssuerSuccess, (state, { issuer }) => ({
    ...state,
    issuer,
    issuerLoaded: true,
  })),
  on(DashboardStoreActions.loadIssuerFailure, (state) => ({
    ...state,
    action: DashboardStateAction.HAS_ERROR,
  })),

  on(DashboardStoreActions.loadDepartments, (state) => ({
    ...state,
    ...initialStateHandling,
  })),
  on(DashboardStoreActions.loadDepartmentsSuccess, (state, { departments }) => ({
    ...state,
    departments,
    action: DashboardStateAction.DEPARTMENTS_LOADED,
  })),
  on(DashboardStoreActions.loadDepartmentsFailure, (state) => ({
    ...state,
    action: DashboardStateAction.HAS_ERROR,
  })),

  on(DashboardStoreActions.updateDetails, (state) => ({
    ...state,
    ...initialStateHandling,
    issuerLoaded: false,
  })),
  on(DashboardStoreActions.updateDetailsSuccess, (state, { issuer }) => ({
    ...state,
    issuer,
    issuerLoaded: true,
  })),
  on(DashboardStoreActions.updateDetailsFailure, (state) => ({
    ...state,
    action: DashboardStateAction.HAS_ERROR,
  })),

  // RESET
  on(DashboardStoreActions.resetValue, (state, { name }) => ({
    ...state,
    ...initialStateHandling,
    [name]: initialState[name],
  })),
  on(DashboardStoreActions.resetState, () => initialState),
);

export function reducer(state: DashboardState, action: Action): DashboardState {
  return dashboardReducer(state, action);
}
