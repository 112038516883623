import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EmailCampaignsGuard } from '../containers/email-campaigns/guards/email-campaigns.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'user',
    pathMatch: 'full',
  },
  {
    path: 'user',
    loadChildren: () =>
      import('../containers/user/user.container.module').then((m) => m.UserContainerModule),
  },
  {
    path: 'department',
    loadChildren: () =>
      import('../containers/department-settings/department-settings.container.module').then(
        (m) => m.DepartmentSettingsContainerModule,
      ),
  },
  {
    path: 'directories',
    loadChildren: () =>
      import('../containers/directories/directories.container.module').then(
        (m) => m.DirectoriesContainerModule,
      ),
  },
  {
    path: 'integrations',
    loadChildren: () =>
      import('../containers/integrations/integrations.container.module').then(
        (m) => m.IntegrationsContainerModule,
      ),
  },
  {
    path: 'email-campaigns',
    canActivate: [EmailCampaignsGuard],
    loadChildren: () =>
      import('../containers/email-campaigns/email-campaigns.container.module').then(
        (m) => m.EmailCampaignsContainerModule,
      ),
  },
  {
    path: 'find-campaigns',
    loadChildren: () =>
      import('../containers/find-campaigns/find-campaigns.container.module').then(
        (m) => m.FindCampaignsContainerModule,
      ),
  },
  {
    path: 'new-email-templates',
    loadChildren: () =>
      import('../containers/email-templates/email-templates.container.module').then(
        (m) => m.EmailTemplatesContainerModule,
      ),
  },
  {
    path: 'groups',
    loadChildren: () =>
      import('../containers/groups/groups.container.module').then((m) => m.GroupsContainerModule),
  },
  {
    path: 'group',
    loadChildren: () =>
      import('../containers/group-settings/group-settings.container.module').then(
        (m) => m.GroupSettingsContainerModule,
      ),
  },
  {
    path: 'collections',
    loadChildren: () =>
      import('../containers/collections/collections.container.module').then(
        (m) => m.CollectionsContainerModule,
      ),
  },
  {
    path: 'credentials',
    loadChildren: () =>
      import('../containers/credentials/credentials.container.module').then(
        (m) => m.CredentialsContainerModule,
      ),
  },
  {
    path: 'pathways',
    loadChildren: () =>
      import('../containers/pathways/pathways.container.module').then(
        (m) => m.PathwaysContainerModule,
      ),
  },
  {
    path: 'insights',
    loadChildren: () =>
      import('../containers/insights/insights.container.module').then(
        (m) => m.InsightsContainerModule,
      ),
  },
  {
    path: 'team-members',
    loadChildren: () =>
      import('../containers/team-members/team-members.container.module').then(
        (m) => m.TeamMembersContainerModule,
      ),
  },
  {
    path: 'team-members/add',
    loadChildren: () =>
      import('../containers/team-member/team-member.container.module').then(
        (m) => m.TeamMemberContainerModule,
      ),
  },
  {
    path: 'team-members/:id',
    loadChildren: () =>
      import('../containers/team-member/team-member.container.module').then(
        (m) => m.TeamMemberContainerModule,
      ),
  },
  {
    path: 'verification',
    loadChildren: () =>
      import('../containers/verification/verification.container.module').then(
        (m) => m.VerificationContainerModule,
      ),
  },
  {
    path: 'not-permitted',
    loadChildren: () =>
      import('../containers/not-permitted/not-permitted.container.module').then(
        (m) => m.NotPermittedContainerModule,
      ),
  },
  {
    path: '404',
    loadChildren: () =>
      import('../containers/db-page-not-found/page-not-found.container.module').then(
        (m) => m.PageNotFoundContainerModule,
      ),
  },
  {
    path: '**',
    loadChildren: () =>
      import('../containers/db-page-not-found/page-not-found.container.module').then(
        (m) => m.PageNotFoundContainerModule,
      ),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class DashboardRoutingModule {}
